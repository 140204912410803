.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topSection {
  border-bottom: var(--border);
  padding: 1em;
  box-sizing: border-box;
}

.reviewContainer {
  background-color: var(--lightGreyBackground);
  margin: 0 auto;
  padding: 1em;
  margin-top: 2em;
  border: var(--border);
  border-radius: 1em;
  min-width: 1000px;
}

.review {
  font-size: 1.2em;
  line-height: 200%;
}

.review b {
  font-weight: 600;
}

.bottomSection {
  flex-grow: 1;
  display: flex;
  min-height: 0;
}

.graph {
  flex-grow: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.history {
  width: 25%;
  min-width: 265px;
  max-width: 500px;
  height: 100%;
  border-left: var(--border);
}

.header {
  font-size: 1.5em;
  font-weight: 600;
  padding-bottom: 0.5em;
}

.description {
  font-size: 1.15em;
  line-height: 1.45em;
}

.description span {
  color: #11428B;
  padding-left: 0.4em;
  cursor: pointer;
}

.subHeader {
  font-size: 1.35em;
  font-weight: 600;
  padding-bottom: 0.5em;
}

.historyRow {
  background-color: #C8DEFF;
  width: 100%;
  height: 60px;
  display: flex;
  margin-bottom: 10px;
}

.historyRow:hover {
  filter: brightness(0.95) saturate(2);
}

.left {
  min-width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 0.95em;
  cursor: pointer;
}

.right {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: 500;
  padding-right: 10px;
}

.dateScore {
  margin-bottom: 0;
}

.dateScore:hover {
  filter: none;
}

.dateScore>.left,
.dateScore>.right {
  cursor: auto;
  background-color: var(--offWhite);
  font-size: 1em;
  font-weight: 500;
}

.mainButtons {
  display: flex;
  align-items: center;
}

.graphContainer {
  flex: 1 1 500px;
}

.refLegend {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 0.95em;
  font-weight: 450;
  padding: 0.25em;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1;
  line-height: 1.3em;
  font-family: var(--headingFont);
}

.refLegend>div {
  padding: 0.25em;
}

.refLegend span {
  font-size: 1.3em;
  padding-right: 0.3em;
}

.customTooltip {
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgb(181, 181, 181);
  border-radius: 1em;
  padding: 0.9em;
  left: -26px;
  top: -22px;
  width: 140px !important;
  font-size: 1.15em;
  position: absolute;
}

.customTooltip>div {
  margin-bottom: 0.5em;
}

.customTooltip>div:last-child {
  margin-bottom: 0em;
}

.customTooltip b {
  font-weight: 500;
}

.reviewInfo {
  padding: 1em;
}

.noScores {
  text-align: center;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noScores p {
  margin: 0;
  margin-bottom: 1.15em;
  font-size: 1.15em;
}

.noScores button {
  margin: 0;
}

.modalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 2em 2em;
  box-sizing: border-box;
  background-color: var(--greyBackground);
  width: 450px;
  border: 2px solid black;
  border-radius: 1em;
  box-shadow: 0px 0px 15px rgba(15, 15, 15, 0.8);
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: 0.5em;
  width: 100%;
  height: 30px;
}

.footer button {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100px !important;
  font-weight: 450;
  color: white;
  background-color: #205098;
}

.historyHeader {
  padding: 0em 0.7em;
  padding-right: 1.8em;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--offWhite);
}

.historyHeader>div:nth-child(1) {
  text-align: left;
  width: 35%;
}

.historyHeader>div:nth-child(2),
.historyHeader>div:nth-child(3) {
  width: 25%;
}

.overviewHistory b {
  font-weight: 550;
}

.overviewHistory {
  min-width: 440px;
  width: 50%;
  max-width: 500px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.overviewRow {
  box-sizing: border-box;
  padding: 0 0.7em;
  padding-right: 1.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
}

.overviewRow div:nth-child(1) {
  width: 35%;
}

.overviewRow div:nth-child(2),
.overviewRow>div:nth-child(3) {
  width: 30%;
  text-align: left;
}

.historyScroll {
  overflow: auto;
}

.userInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.userInfo>div {
  margin-bottom: 0.8em;
}

.userInfo b {
  font-weight: 550;
}

.userInfo>div {
  font-size: 1.35em;
  margin-right: 0em;
  padding-right: 1em;
}

.userInfo>div:last-child {
  margin-right: 0em;
  padding-right: 0em;
}

.pdfIcon {
  background-image: url('../images/Report.png') !important;
}

.sendIcon {
  background-image: url('../images/Send.png');
  background-position: center;
  background-size: contain;
  height: 1.1em;
  width: 1.1em;
  margin-right: 0.25em;
  transform: translate(-4px, 4px);
  display: inline-block;
}

.hiddenGraph {
  position: fixed;
  top: 0;
  left: -9999px;
  width: 750px;
  height: 600px;
  background-color: rgb(255, 255, 255);
  z-index: 2;
}

.notePrompt {
  font-size: 1.15em;
  font-weight: 500;
  margin-right: 0.5em;
}

.noteBtn {
  margin-right: 0.5em;
}

.notePromptWrapper {
  display: flex;
  align-items: center;
}

.notes {
  padding: 0.5em;
  box-sizing: border-box;
  font-size: 1.15em;
  height: 200px;
  max-width: 99%;
  min-width: 99%;
  margin-bottom: 1em;
}

.footer > a {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: 100px;
  height: 50px;
}