.container {
  width: 240px;
  height: calc(100% - var(--topNavHeight));
  background-color: rgb(220, 220, 220);
  border-right: var(--border);
  padding: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 55px;
  z-index: 1;
}

.container span {
  display: block;
  padding-bottom: 1em;
  cursor: pointer;
}

.menuList {
  padding: 0 !important;
min-height: 0;
overflow: auto;
}

.container div {
  font-size: 1.1em;
  padding: 0.8em 0;
}

.container a {
  font-weight: 500;
  text-decoration: none;
  color: rgb(52, 52, 52);
}

.container a:hover {
  color: rgb(14, 14, 14);
}

.picked {
  color: #11428B !important;
}

.closedMenu {
  padding: 0.5em;
  height: calc(100% - var(--topNavHeight));
  box-sizing: border-box;
  width: 36px;
  cursor: pointer;
  background-color: rgb(227, 227, 227);
  border-right: var(--border);
  position: fixed;
  top: 55px;
  z-index: 1;
}