.container {
  padding-top: 2vh;
  width: 100%;
  background-image: url('../images/LoginBackground.png');
  background-position: center;
  background-size: 140%;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
  filter: hue-rotate(365deg) saturate(1.05);
  background-attachment: fixed;
}

.container>div {
  margin: 0 auto;
  width: 450px;
  border-radius: 1em;
  border: var(--border);
  padding: 2em;
  color: var(--offBlack);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}


.container>div>div {
  font-size: 1.1em;
}

.container h1 {
  margin-top: 0;
}

.studyManagerLbl {
  font-size: 1.5em;
  padding-bottom: 0.5em;
}

.container select {
  font-size: 1.4em;
  margin-bottom: 1em;
  padding: 0.25em;
  width: 250px;
}

.container a:last-child {
  margin-bottom: 0em;
}

.container a {
  display: block;
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: 2em;
  color: rgb(15, 86, 144);
}

.container a:hover {
  color:  rgb(10, 113, 196) !important;
}

.container a:visited {
  color: rgb(15, 86, 144);
}