.container {
  margin-top: 2vh;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container>div {
  width: 100%;
}

.userTable {
  border-spacing: 0;
  border-radius: 1em;
  border: var(--border);
  font-size: 1em;
  width: 100%;
  max-width: var(--adminTableMaxWidth);
  margin: 0 auto;
}

.userTable th,
.userTable td {
  padding: 0.75em;
  text-align: center;
  border-bottom: var(--border);
  border-right: var(--border);
  border-width: 2px;
  background-color: var(--lightGreyBackground);
}

.userTable thead>tr:first-child {
  display: none;
}


.userTable thead>tr:last-child th:first-child {
  border-radius: 0.6em 0 0 0;
}

.userTable thead>tr:last-child th:last-child {
  border-radius: 0 0.6em 0 0;
}

.userTable tbody>tr:last-child {
  border-radius: 0.6em 0.6em 0 0;
}

.userTable tbody>tr:last-child td:first-child {
  border-radius: 0 0 0 0.6em;
}

.userTable tbody>tr:last-child td:last-child {
  border-radius: 0 0 0.6em 0;
}

.userTable tbody>tr td:last-child {
  width: 180px;
  padding: 0;
}


.userTable tr>td:last-child,
.userTable tr>th:last-child {
  border-right: none;
}

.userTable tr:last-child>td {
  border-bottom: none;
}

.userTable td {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.userTable button {
  background-color: white;
  padding: 0.5em 0.5em;
  font-weight: 500;
  margin: 0em 0.25em;
  font-size: 0.85em;
  border: 1px solid rgb(151, 151, 151);
  border-radius: 0.25em;
  cursor: pointer;
  box-shadow: none;
}

.userTable button:hover {
  background-color: rgb(201, 201, 201);
}

.userTable thead>tr:first-child {
  font-size: 1.22em !important;
}

.userTable thead>tr:first-child>th {
  font-weight: 600;
}

.userTable thead,
.userTable tbody {
  font-size: 1.05em !important;
}

.userTable th {
  font-weight: 500;
}

.activeLinksHeader {
  max-width: var(--adminTableMaxWidth);
  margin: 0 auto;
  padding: 0.75em 0;
  display: flex;
  align-items: center;
  padding-right: 0.25em;
}

.activeLinksHeader>h2 {
  margin: 0;
  flex-grow: 1;
  font-weight: 500;
  padding-left: 0.15em;
}

.activeLinksHeader>div button {
  font-size: 1.1em;
  margin-right: 0 !important;
  font-weight: 500;
}

.searchContainer input {
  width: 200px;
  margin-right: 1em;
  font-size: 1.2em;
  height: 34px;
  transform: translateY(1px);
  border-radius: 0.25em;
  padding-left: 35px;
  border: 2px solid rgb(183, 183, 183);
  background-color: rgb(241 241 241);
}

.searchContainer {
  position: relative;
  display: inline-block;
}

.searchContainer::before {
  content: "";
  /* Search icon */
  filter: contrast(0.3);
  position: absolute;
  left: 10px;
  top: 50%;
  background-image: url('../images/Search.png');
  background-position: center;
  background-size: contain;
  height: 1.2em;
  width: 1.2em;
  transform: translateY(-48%);
  font-size: 15px;
  z-index: 1;
}

.sendIcon {
  background-image: url('../images/Send.png');
  background-position: center;
  background-size: contain;
  height: 1.2em;
  width: 1.2em;
  transform: translate(-4px, 4px);
  display: inline-block;
}

.link {
  background-image: url('../images/Link.png');
  background-position: center;
  background-size: contain;
  height: 1.4em;
  width: 1.4em;
  margin-right: 0.25em;
  display: inline-block;
  filter:contrast(0.85);
}

.usersIcon {
  background-image: url('../images/Users.png');
  background-position: center;
  background-size: contain;
  height: 1.4em;
  width: 1.4em;
  margin: 0 0.4em;
  display: inline-block;
  filter:contrast(0.85);
}