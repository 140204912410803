@font-face {
  font-family: 'Inter';
  src: url('./styles/fonts/Nunito.ttf');
}

:root {
  --topNavHeight: 55px;
  --border: 3px solid rgb(183, 183, 183);
  --offWhite: rgb(252, 252, 252);
  --offBlack: rgb(37, 37, 37);
  --greyBackground: rgb(220, 220, 220);
  --lightGreyBackground: rgb(235, 235, 235);
  --adminTableMaxWidth: 1200px;
}

body,
html {
  width: 100%;
  height: 100%;
}

body, button {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--offWhite);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

button,
.btn {
  font-size: 1.15em;
  padding: 0.4em 0.8em;
  margin-right: 1.5em;
  background: rgb(230, 230, 230);
  border-radius: 0.25em;
  box-shadow: 0px 1px 1px rgba(78, 78, 78, 0.6);
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  font-weight: 500;
}

.btn>a {
  text-decoration: none;
  color: var(--offBlack);
}

button:hover,
.btn:hover {
  transform: scale(1.003);
  box-shadow: 0px 1px 2px rgba(78, 78, 78, 0.7);
}

.recharts-text {
  font-size: 1.05em;
  fill: rgb(87, 87, 87);
}

input, select, button {
  font-family: 'Inter';
}

.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 200px;
}

/* The popup chat - hidden by default */
.chat-popup {
  position: -webkit-sticky; /*Safari*/
  display: none;
  position: sticky;
  bottom: 8px;
  left: 8px;
  width:max-content;
  margin-left:auto;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 500px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

table td {
  border: 1px solid;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  text-align: center;
}

tr:nth-child(even){background-color: white;}

thead { background-color: rgb(172, 234, 255)}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  overflow-x:auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.modal-content {
  word-wrap: break-word;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



/* Style The Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}