.App {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--offBlack);
}

.main {
  height: calc(100% - var(--topNavHeight));
  width: 100%;
  display: flex;
  position: absolute;
  top: 55px;
}

.sideMenuOpen {
  width: calc(100% - 240px);
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  left: 240px;
  background-color: var(--offWhite);
}

.sideMenuClosed {
  width: calc(100% - 36px);
  height: 100%;
  position: absolute;
  left: 36px;
  background-color: var(--offWhite);
}

.loginBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(3,6,56);
  /*background: linear-gradient(42deg, rgba(3,6,56,1) 0%, rgba(138,203,241,1) 72%, rgba(247,239,255,1) 100%);*/
  background-image: url('../images/LoginBackground.png');
  background-position: center;
  background-size: 120%;
  filter: hue-rotate(365deg) saturate(1.05);
}

.loginBox>div {
  padding: 1em 0;
  width: 300px;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
  margin-top: -10%;
  border-radius: 1em;
  border: var(--border);
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(8px);
}

.logo {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.75em 0;
}

.logo>img {
  height: 100%;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
}

.content h2 {
  margin-top: 0;
  font-size: 1.5em;
}

.input {
  font-weight: 600;
  padding-top: 1em;
  font-size: 1.2em;
}

.input input {
  margin-top: 0.2em;
  font-size: 1em;
}

.loginBtn {
  margin: 0;
  margin-top: 2em;
  background-color: #11428B;
  color: white;
  font-weight: 600;
  font-size: 1.25em;
}