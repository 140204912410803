
.container {
  margin-top: 2vh;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container>div {
  width: 100%;
}


.overviewTable {
    border-spacing: 0;
    border-radius: 1em;
    border: var(--border);
    font-size: 1em;
    width: 100%;
    max-width: var(--adminTableMaxWidth);
    margin: 0 auto;
  }

.overviewTable thead {
  background-color: var(--lightGreyBackground)
}

.overviewTable th,
.overviewTable td {
  padding: 0.75em;
  text-align: center;
  border-bottom: var(--border);
  border-right: var(--border);
  border-width: 2px;
  
  background-color: var(--white);
}